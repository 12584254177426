




































import BaseCard from "@/components/Base/BaseCard.vue";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
    components: {
        BaseCard,
    },
})
export default class AdminDashboardCharts extends Vue {
    @Prop() statistics!: any[];

    get useGutter() {
        return this.$vuetify.breakpoint.mdAndUp;
    }
}
